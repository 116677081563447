<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        Bank transfer settings
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.bank-transfers.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <list-fields
                    :fields="fields"
                    :item="item"
                />
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import BankTransfer from "../../../models/BankTransfer";
import ListFields from "../../../components/app/form/ListFields";

export default {
    name: "bank-transfers-show",
    components: {
        AdminDashboardLayout,
        ListFields,
    },
    data: function () {
        return {
            fields: [
                {code: 'user_id', label: 'User ID'},
                {code: 'user_name', label: 'User Name'},
                {code: 'swift_code', label: 'SWIFT Code'},
                {code: 'iban_number', label: 'IBAN Number'},
                {code: 'bank_name', label: 'Bank Name'},
                {code: 'bank_country', label: 'Bank Country',},
                {code: 'beneficiary_name', label: 'Beneficiary Name'},
                {code: 'beneficiary_street', label: 'Beneficiary Street'},
                {code: 'beneficiary_city', label: 'Beneficiary City'},
                {code: 'beneficiary_country', label: 'Beneficiary Country'},
                {code: 'beneficiary_state', label: 'Beneficiary State'},
                {code: 'beneficiary_zip', label: 'Beneficiary Zip'},
                {code: 'created_at', label: 'Created Time', type: 'date'},
                {code: 'updated_at', label: 'Updated Time', type: 'date'},
            ],
            item: {},
        }
    },
    async mounted() {
        const bankTransferId = this.$route.params.id
        this.item = await BankTransfer.find(bankTransferId)
    },
}
</script>

<style scoped>

</style>
