<template>
    <v-form>
        <v-row v-for="(field, code) in item" :key="code">
            <v-col>
                <v-text-field v-if="isDateByCode(fields, code) && moment(field).isValid()"
                              :label="getLabelByCode(fields, code)"
                              :value="moment(field).format('lll')"
                              readonly></v-text-field>
                <v-text-field v-else
                              :label="getLabelByCode(fields, code)"
                              :value="field"
                              readonly></v-text-field>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import {getLabelByCode, isDateByCode} from "../../../helpers/functions";

export default {
    name: "list-fields",
    props: {
        fields: {
            type: Array,
            default() {
                return []
            }
        },
        item: {}
    },
    methods: {
        getLabelByCode,
        isDateByCode,
    },
}
</script>
